<template>
  <div id="login">
    <div class="login-header">
      <img
        :src="require('../assets/' + appBrand + '/logo.png')"
        class="login-logo"
      />
      <h1>
        Admin
        <span
          v-if="$cookies.get('version') === 'dev'"
          @click="changeEnvironment('live')"
          >Dev</span
        >
        <span
          v-if="$cookies.get('version') === 'live'"
          @click="changeEnvironment('dev')"
          >Live</span
        >
      </h1>
    </div>

    <div class="login-form">
      <span class="login-message" v-if="messageString">{{
        messageString
      }}</span>
      <input v-model="email" type="email" placeholder="E-mail" />
      <input
        v-model="password"
        type="password"
        placeholder="Password"
        v-on:keyup.enter="login"
      />
      <button @click="login">Log in</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
// import { db } from '../main'
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loader: false,
      messageString: "",
    };
  },
  computed: {
    ...mapState({
      appBrand: (state) => state.settings.appSettings.app,
    }),
  },
  mounted() {},
  methods: {
    changeEnvironment: function (to) {
      let confirm = window.confirm(
        "About to change to: " +
          to +
          " \r\nPage will reload, do you wish to continue?"
      );

      if (confirm) {
        this.$cookies.set("version", to);
        location.reload();
      }
    },
    login: function () {
      if (this.loader) {
        return false;
      }

      this.messageString = "";
      this.loader = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          (data) => {
            console.log(data.user.uid);
          },
          (err) => {
            console.log(err);
            this.loader = false;
            this.messageString = err.message;

            // switch(err.code) {
            //   case 'auth/invalid-email':
            //     this.messageString = err.message;
            //     break;
            //   case 'auth/wrong-password':
            //     this.messageString = 'Lösenordet matchar inte e-postadressen.';
            //     break;
            //   case 'auth/user-not-found':
            //     this.messageString = 'Ingen användare matchar den angivna e-postadressen.';
            //     break;
            // }
          }
        );
    },
  },
};
</script>
